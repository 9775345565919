<template>
    <v-dialog
    v-model="showing_modal"
    width="800">
        <v-card>
					<v-card-text>
						<v-data-table
							:headers="tableColumns"
							:items="contactsListTable"
							:page="current_page"
							:server-items-length="totalContactsListTable"
							:loading="loading"
							:footer-props="{ 'items-per-page-options': [5, 10, 15] }"
							@update:page="changingContactsPage($event)"
							@update:items-per-page="changingContactsItemsPerPage($event)"
						>
							<!-- NO data -->
							<template v-slot:no-data>
								<v-list-item>
									<v-list-item-title>
										No hay
										<strong>Contactos</strong>
										disponibles para esta búsqueda
									</v-list-item-title>
								</v-list-item>
							</template>
	
							<!-- Name -->
							<template #[`item.name`]="{ item }">
								<v-btn
									class="no-uppercase"
									text
									@click="$refs.contacts_details_modal.open(item)"
								>
									{{ item.name }}
								</v-btn>
							</template>
							<!-- Phone -->

							<template #[`item.phones`]="{ item }">
								<div>
									<div v-for="(number, idx) in item.phones" clasS="d-flex">
										<span v-if="idx < 2">{{ number.phone }}</span>
										<v-tooltip right v-if="idx < 2">
											<template v-slot:activator="{ on, attrs }">
												<v-icon class="ml-0" :color="number.correct === true ? 'success' : number.correct === false ? 'error' : 'warning'" dense v-bind="attrs" v-on="on">
													{{ number.correct === true ? icons.mdiCheckCircleOutline : number.correct === false ? icons.mdiCloseCircleOutline : icons.mdiInformationOutline}}
												</v-icon>
											</template>
											<span>{{ number.correct === true ? 'Confirmado' : number.correct === false ? 'Dado de baja' : 'Sin datos de confirmación' }}</span>
										</v-tooltip>
									</div>
									<div class="d-flex" v-if="item.phones.length > 2">
										<v-tooltip right>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													icon
													x-small
													color="primary"
													v-bind="attrs"
													v-on="on"
													@click="$refs.contact_numbers_modal.openModal(item.phones)"
												>
													<v-icon>
														{{ icons.mdiDotsHorizontal }}
													</v-icon>
												</v-btn>
											</template>
											<span>
												Ver todos los números
											</span>
										</v-tooltip>
									</div>
								</div>
							</template>
	
							<!--Tag -->
							<template #[`item.tags`]="{ item }">
								<v-btn
									class="no-uppercase tag-container my-1"
									text
									max-height="26px"
									v-for="tag in item.tags"
								>
									<v-icon class="mr-1" dense small>{{ icons.mdiTag }}</v-icon>
									<span>{{ tag.name }}</span>
								</v-btn>
							</template>
						</v-data-table>
					</v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close()">
                CERRAR
                </v-btn>
            </v-card-actions>
						<ContactsDetailsModal ref="contacts_details_modal" />
						<ContactNumbersModal ref="contact_numbers_modal" />
        </v-card>
    </v-dialog>
</template>

<script>
const debounce = require("debounce");
import ContactsDetailsModal from "@/components/Contacts/ContactsDetailsModal";
import ContactNumbersModal from "@/components/Contacts/ContactNumbersModal";

import {
  mdiTag,
	mdiCheckCircleOutline,
	mdiCloseCircleOutline,
	mdiInformationOutline,
	mdiDotsHorizontal
} from "@mdi/js";

export default {
    data: () => {
        return {
            showing_modal: false,
            loading: false,
            contacts: [],
            message: null,
						totalContactsListTable: 0,
            contactsListTable: [],
						tagID: null,
						current_page: 1,
            page_size: 10,
        }
    },
		components: {
			ContactsDetailsModal,
			ContactNumbersModal,
		},
    methods: {
        async open(id) {
					this.tagID = id
					this.getTagContacts()
					this.showing_modal = true
					console.log(this.contacts.message)
        },

				handleSearch: debounce(function (e) {
					this.current_page = 1;
					this.getTagContacts();
				}, 400),

				changingContactsPage(e) {
					this.current_page = e;
					this.getTagContacts();
				},

				changingContactsItemsPerPage(e) {
					this.page_size = e;
					this.getTagContacts();
				},

				async getTagContacts() {
					this.loading = true;
					try {
						const query = {
							page_size: this.page_size,
							page: this.current_page,
							id: this.tagID
							// batch_id: 4,
						};

						const response = await this.$api.getTagContacts(query);
						this.contactsListTable = response.results;
						this.totalContactsListTable = response.count;
					} catch (error) {
						console.log(error);
					} finally {
						this.loading = false;
					}
				},

        close() {
            this.contactsListTable = []
						this.totalContactsListTable = 0
						this.tagID = null
            this.showing_modal = false
        }
    },
		setup() {

			const tableColumns = [
        { text: "Nombre", value: "name", align: "center", sortable: false },
        { text: "Teléfonos", value: "phones", align: "center", sortable: false },
        { text: "Tags", value: "tags", align: "center", sortable: false },
        { text: "DNI", value: "dni", align: "center", sortable: false },
      ]

			return {
				tableColumns,
				icons: {
					mdiTag,
					mdiCheckCircleOutline,
					mdiCloseCircleOutline,
					mdiInformationOutline,
					mdiDotsHorizontal
				}
			}
		}
}
</script>

<style scoped>

.no-uppercase {
  text-transform: unset !important;
}

.tag-container {
  color: white;
  background-color: rgb(120, 120, 120);
  font-size: 14px;
}

.tag-container:hover {
  cursor: default;
}
</style>