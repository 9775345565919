import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{model:{value:(_vm.showingModal),callback:function ($$v) {_vm.showingModal=$$v},expression:"showingModal"}},[_c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.phones,"page":_vm.current_page,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15] }},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.number)+" ")])]}},{key:"item.correct",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('span',[_vm._v(" "+_vm._s(item.correct === true ? 'Confirmado' : item.correct === false ? 'Dado de baja' : 'Sin datos de confirmación')+" ")]),_c(VIcon,{staticClass:"ml-0",attrs:{"color":item.correct === true ? 'success' : item.correct === false ? 'error' : 'warning',"dense":""}},[_vm._v(" "+_vm._s(item.correct === true ? _vm.icons.mdiCheckCircleOutline : item.correct === false ? _vm.icons.mdiCloseCircleOutline : _vm.icons.mdiInformationOutline)+" ")])],1)]}}],null,true)}),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CERRAR ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }